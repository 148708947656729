<script setup>
import { computed } from "vue";

const emit = defineEmits(["update:checked"]);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit("update:checked", val);
    },
});
</script>

<template>
    <div class="flex items-center">
        <input
            v-model="proxyChecked"
            type="checkbox"
            :value="value"
            class="rounded border-gray-300 text-amber-600 shadow-sm focus:ring-amber-500"
        />
        <p class="pl-3">{{ label }}</p>
    </div>
</template>
